<template>
  <div class="content-wrapper view-aptlist-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-chart-bar"></i> รายงานรายได้แพทย์</h4>
          <b-row>
            <b-col cols="12" md="6">
              <b-col cols="12">
                <b-form-group
                  block
                  label-size="sm"
                  label="แพทย์:"
                  label-cols="2"
                  label-align="right"
                  invalid-feedback="*กรุณาเลือกแพทย์"
                  :state="Boolean(selectedDoctor)"
                  class="mb-1"
                >
                  <b-form-select
                    size="sm"
                    v-model="selectedDoctor"
                    :options="doctorsList"
                    text-field="fullName"
                    value-field="uid"
                    :disabled="
                      !isAccessMenu(['MANAGER', 'COUNTER', 'HEADCOUNTER'])
                    "
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- กรุณาเลือกแพทย์ --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  block
                  label-size="sm"
                  label="วันที่:"
                  label-cols="2"
                  label-align="right"
                  invalid-feedback="*กรุณาเลือกวันที่"
                  :state="Boolean(dateRange[0] && dateRange[1])"
                >
                  <date-picker
                    v-model="dateRange"
                    type="date"
                    range
                    format="DD MMM YY"
                    :shortcuts="dateRangeShortcuts"
                    :disabled-date="notAfterToday"
                    placeholder="เลือกวันที่ต้องการค้นหา"
                    input-class="form-control form-control-sm"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col>
              <b-form-group class="mb-2">
                <b-form-checkbox-group size="sm" v-model="itemsType">
                  <b-form-checkbox :value="1" size="sm"
                    >รักษาทั่วไป</b-form-checkbox
                  >
                  <b-form-checkbox :value="2" size="sm"
                    >แพ็กเกจจัดฟัน</b-form-checkbox
                  >
                  <b-form-checkbox :value="3" size="sm"
                    >ยา และอุปกรณ์</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group> </b-col
            ><b-col cols="6" md="3" lg="2">
              <b-button class="float-right" variant="primary" @click="fetchAll">
                แสดงข้อมูล
              </b-button>
            </b-col>
          </b-row>

          <!-- <b-col cols="12" sm="2">
              
            </b-col>-->
          <div :class="{ 'my-fullscreen': isFullscreen }">
            <b-row>
              <b-col cols="7" md="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  size="sm"
                  first-number
                  last-number
                  pills
                  limit="10"
                ></b-pagination>
              </b-col>

              <b-col>
                <b-button
                  size="sm"
                  class="float-right"
                  variant="info"
                  @click="excelExport()"
                >
                  <i class="fas fa-file-excel"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="float-right mr-2"
                  variant="secondary"
                  @click="isFullscreen = !isFullscreen"
                >
                  <i class="fas fa-expand"></i>
                </b-button>
              </b-col>
            </b-row>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="รายได้แพทย์" body-class="p-1" active>
                  <b-table
                    id="personalIncomeTable"
                    ref="personalIncomeTable"
                    class="my-0 sticky-footer"
                    small
                    sort-icon-left
                    head-variant="light"
                    :busy="tableBusy"
                    :items="filteredPersonalIncomeList"
                    :fields="visbleFields"
                    sort-by="creationDt"
                    v-model="visibleRows"
                    :sort-desc="true"
                    :responsive="true"
                    show-empty
                    :sticky-header="tableHeight"
                    :per-page="perPage"
                    :current-page="currentPage"
                  >
                    <template #cell(index)="row">{{ row.item.row }}.</template>
                    <template #cell(creationDt)="row">
                      {{ formatDate(row.value) }}
                    </template>
                    <template #cell(treatType)="row">
                      {{ formatType(row.value) }}
                    </template>
                    <template #cell(button)="row">
                      <b-button
                        class="show-when-hovered-d-none"
                        size="xs"
                        variant="outline-secondary"
                        @click="openReceiptModal(row.item)"
                      >
                        <i class="fas fa-file-invoice-dollar"></i>
                      </b-button>
                    </template>

                    <template #custom-foot>
                      <b-tr variant="secondary" class="text-bold text-right">
                        <td class="text-left">รวม</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <!-- <td>{{ sum("amount") }}</td> -->
                        <td>{{ sum("paid") }}</td>
                        <!-- <td class="text-danger">
                        {{ sum("overdue") }}
                      </td>-->
                        <td></td>
                        <td>{{ sum("processingFeeBaht") }}</td>
                        <td>{{ sum("paidDf") }}</td>
                        <td v-if="reportSetting.cfVisible">
                          {{ sum("paidCf") }}
                        </td>
                        <td></td>
                      </b-tr>
                    </template>
                    <template #empty>
                      <p class="text-center text-muted my-2">
                        ไม่มีข้อมูลให้แสดง
                      </p>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="ค่าแลป">
                  <DoctorLabExpeseTable :labReport="labReport" />
                </b-tab>
                <b-tab title="สรุป">
                  <DfSummaryTable
                    :total="total"
                    :reportSetting="reportSetting"
                    @updateIsDeducted="handleDeductChange"
                  />
                </b-tab>
                <template #tabs-end>
                  <b-form-checkbox
                    class="pt-2 ml-2"
                    v-model="reportSetting.cfVisible"
                    switch
                    @change="handleCfVisible"
                  >
                    แสดงส่วนแบ่งคลินิก(CF)
                  </b-form-checkbox></template
                >
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
      <!-- <div class="d-none">
        <div ref="header" id="printHeader">
          <h4>รายงานรายได้แพทย์</h4>
          <h6 v-if="getDoctorListBy(selectedDoctor, 'value')">
            ทพ./ทพญ.
            {{ getDoctorListBy(selectedDoctor, "value").label }}
            ตั้งแต่วันที่
            {{
              formatDate(dateRange.startDate) +
              "-" +
              formatDate(dateRange.endDate)
            }}
          </h6>
          <span class="text-right">
            พิมพ์วันที่ {{ formatDate(today) }} จัดทำโดย
            {{ userInfo.firstNameTh + " " + userInfo.lastNameTh }}</span
          >
        </div>
        <div ref="footer" id="printFooter">
          <h3>Footer</h3>
        </div>
      </div>-->
      <PaymentReceiptPreviewModal
        ref="paymentReceiptPreviewModal"
      ></PaymentReceiptPreviewModal>
      <PdfExport ref="pdfExport" />
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";
import DoctorLabExpeseTable from "@/components/reports/DoctorLabReport";
import DfSummaryTable from "@/components/reports/DfSummaryTable";

import PdfExport from "@/components/PdfExport";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import XLSX from "xlsx";

import { eventBus } from "../main";

export default {
  name: "PersonalIncomeReport",
  components: {
    DoctorLabExpeseTable,
    DfSummaryTable,
    // DateRangePicker,
    PdfExport,
    PaymentReceiptPreviewModal,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      // Doctors

      selectedDoctor: null,

      // Date range picker

      dateRange: [],

      //table
      tableBusy: false,
      personalIncomeList: [],
      labReport: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN.",
          class: "narrow-spacing",
        },
        {
          key: "patientFullName",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
        },
        {
          key: "itemName",
          label: "รายการรักษา",
          class: "narrow-spacing",
        },
        {
          key: "treatType",
          label: "ประเภท",
          class: "narrow-spacing",
        },

        {
          key: "price",
          label: "ค่ารักษา",
          class: "text-right",
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          class: "text-right",
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right",
        },
        {
          key: "overdue",
          label: "ค้างชำระ",
          class: "text-right text-danger",
        },
        {
          key: "processingFeeBaht",
          label: "ค่าธรรมเนียม",
          class: "text-right",
          thClass: "narrow-spacing",
        },
        {
          key: "paidDf",
          label: "DF",
          class: "text-right",
        },
        {
          key: "paidCf",
          label: "CF",
          class: "text-right",
          hidden: true,
        },
        {
          key: "button",
          label: "",
          class: "p-0 align-middle",
        },
      ],
      visibleRows: [],

      loadingTime: 0,
      itemsType: [],
      feeMethods: [],

      total: {
        paid: 0,
        paidDf: 0,
        paidCf: 0,
        labTotal: 0,
        labDf: 0,
        labCf: 0,
      },

      //Excel Export
      tableList: [
        { id: "personalIncomeTable", sheetName: "รายได้แพทย์" },
        { id: "doctorLabReport", sheetName: "ค่าแลป" },
        { id: "doctorSummaryReport", sheetName: "สรุป" },
      ],

      // pagination
      currentPage: 1,
      perPage: 100,

      isFullscreen: false,
      tableTopPos: null,
      reportSetting: {
        cfVisible: false,
        isDeducted: false,
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      doctorsList: "moduleUser/getDoctorList",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      userInfo: "moduleUser/getUserInfo",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      getReportDateRange: "moduleReport/getReportDateRange",
      paymentMethodList: "moduleUser/getPaymentMethodList",
      isManager: "moduleUser/isManager",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
      getLabExpenseList: "moduleLab/getLabExpenseList",
    }),
    filteredPersonalIncomeList() {
      let list = this.personalIncomeList;
      if (this.itemsType.length !== 0) {
        list = list.filter(i => this.itemsType.includes(i.treatType));
      }
      return list
        .slice(0)
        .reverse()
        .map((i, index) => {
          return { ...i, row: index + 1 };
        });
    },

    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    dateFromUTC() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").utc(true).format()
        : "";
    },
    dateToUTC() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").utc(true).format()
        : "";
    },
    rows() {
      return this.filteredPersonalIncomeList.length;
    },
    tableHeight() {
      if (!this.isFullscreen) {
        return `calc(100vh - ${this.tableTopPos + 40}px)`;
      } else {
        return `calc(100vh - 190px)`;
      }
    },
    visbleFields() {
      return this.fields.filter(i => !i.hidden);
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportDoctorReceiptItem: "moduleReport/fetchReportDoctorReceiptItem",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
      fetchPaymentMethodList: "moduleUser/fetchPaymentMethodList",
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchReceiptById: "moduleReceipt/fetchReceiptById",
      fetchLabExpenseForReport: "moduleLab/fetchLabExpenseForReport",
    }),
    init() {
      this.fetchPaymentMethodList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      }).then(() => {
        this.feeMethods = this.paymentMethodList.filter(i => i.processingFee);
      });
    },
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatType(code) {
      switch (code) {
        case 1:
          return "รักษาทั่วไป";
        case 2:
          return "แพ็กเกจจัดฟัน";
        case 3:
          return "ยา และอุปกรณ์";
      }
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(arg) {
      let num = this.filteredPersonalIncomeList.reduce((accum, item) => {
        return accum + parseFloat(item[arg]);
      }, 0.0);
      return Math.round(num * 100) / 100;
    },
    sendEmail() {},
    async fetchReport() {
      this.isLoading = true;
      this.setStateReportDateRange(this.dateRange);

      await this.fetchReportDoctorReceiptItem({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        actionUid: this.selectedDoctor,
      })
        .then(res => {
          this.personalIncomeList = res.data.report;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async fetchLabReport() {
      await this.fetchLabExpenseForReport({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        event: {
          fromDfDate: this.dateRange[0] ? this.dateFromUTC : null,
          toDfDate: this.dateRange[1] ? this.dateToUTC : null,
          uid: this.selectedDoctor,
        },
      }).then(res => {
        this.labReport = res.data.filter(i => !i.cancelDt);
      });
    },
    async excelExport() {
      this.perPage = null;
      await this.$nextTick();
      document.querySelectorAll(".sr-only").forEach(e => e.remove());
      let wb = XLSX.utils.book_new();
      let filename = "report.xlsx";

      this.tableList.forEach(i => {
        let tbl = document.getElementById(i.id);
        let ws = XLSX.utils.table_to_sheet(tbl);
        XLSX.utils.book_append_sheet(wb, ws, i.sheetName);
      });

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
      this.perPage = 100;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    openReceiptModal(data) {
      const receiptPromise = this.fetchReceiptById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.id,
      });
      const patientPromise = this.fetchPatientById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.patientId,
      });

      this.isLoading = true;
      Promise.all([receiptPromise, patientPromise])
        .then(responseAll => {
          if (responseAll) {
            const [receiptRes, patientRes] = responseAll;
            if (receiptRes.status == 200 && patientRes.status == 200) {
              if (receiptRes.data.id && patientRes.data.id) {
                this.$refs.paymentReceiptPreviewModal.show(
                  receiptRes.data,
                  patientRes.data,
                  receiptRes.data.cancel ? "cancel" : "copy",
                  this.isManager,
                  true
                );
              } else {
                eventBus.$emit("alertSwal", {
                  title: "ระบบขัดข้อง",
                  message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
                  icon: "error",
                });
              }
            }
          }
        })
        .catch(err => {
          console.error("catch:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calSummaryTable() {
      this.total = {
        paid: this.calSum(this.personalIncomeList, "paid"),
        paidDf: this.calSum(this.personalIncomeList, "paidDf"),
        paidCf: this.calSum(this.personalIncomeList, "paidCf"),
        labTotal: this.calSum(this.labReport, "price"),
        labDf: this.calSum(this.labReport, "dfAmount"),
        labCf: this.calSum(this.labReport, "cfAmount"),
      };
    },
    calSum(list, key) {
      let num = list.reduce((accum, item) => {
        return accum + parseFloat(item[key]);
      }, 0);
      return Math.round(num * 100) / 100;
    },
    fetchAll() {
      Promise.all([this.fetchReport(), this.fetchLabReport()]).then(res => {
        this.calSummaryTable();
      });
    },
    handleCfVisible() {
      localStorage.setItem("reportSetting", JSON.stringify(this.reportSetting));
      this.fields.forEach(field => {
        if (field.key === "paidCf") {
          field.hidden = !this.reportSetting.cfVisible;
        }
      });
    },
    handleDeductChange(e) {
      this.reportSetting.isDeducted = e;
      localStorage.setItem("reportSetting", JSON.stringify(this.reportSetting));
    },
  },
  async mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
    if (this.isAccessMenu(["DOCTOR"])) {
      this.selectedDoctor = this.userInfo.uid;
    }
    await this.$nextTick();

    this.tableTopPos = document
      .querySelector("#personalIncomeTable")
      .getBoundingClientRect().top;
    this.reportSetting = await JSON.parse(
      localStorage.getItem("reportSetting") || "{}"
    );
    this.handleCfVisible();
  },
  updated() {},
};
</script>

<style>
</style>
