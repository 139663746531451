<template>
  <div>
    <b-table-simple
      id="doctorSummaryReport"
      ref="doctorSummaryReport"
      hover
      small
      responsive
    >
      <b-thead head-variant="light">
        <b-tr class="text-right">
          <b-th></b-th>
          <b-th>แพทย์</b-th>
          <b-th v-if="reportSetting.cfVisible">คลินิก</b-th>
          <b-th v-if="reportSetting.cfVisible">รวม</b-th>
        </b-tr></b-thead
      >
      <b-tbody>
        <b-tr>
          <b-th>รายได้</b-th>
          <b-td class="text-right">{{ total.paidDf }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.paidCf
          }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.paid
          }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>ค่าแลป</b-th>
          <b-td class="text-right">{{ total.labDf }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.labCf
          }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.labTotal
          }}</b-td>
        </b-tr>
        <b-tr :class="isDeducted ? '' : 'text-muted'">
          <b-th
            ><b-form-checkbox
              v-model="isDeducted"
              @change="handleDeductChange"
              switch
            >
              หักค่าใช้จ่าย 10% ตามสัญญาแพทย์</b-form-checkbox
            >
            <!-- ><b-form-checkbox
              :value="isDeducted"
              switch
              @change="handleDeductChange($event)"
            >
              หักค่าใช้จ่าย 10% ตามสัญญาแพทย์</b-form-checkbox
            > -->
          </b-th>
          <b-td class="text-right">{{ deductDf }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right"></b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right"></b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr variant="secondary" class="text-bold">
          <b-th>รวม</b-th>
          <b-td class="text-right">{{
            total.paidDf - total.labDf - deductDf
          }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.paidCf - total.labCf
          }}</b-td>
          <b-td v-if="reportSetting.cfVisible" class="text-right">{{
            total.paid - total.labTotal
          }}</b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  name: "dfSummaryTable",
  components: {},
  props: ["total", "reportSetting"],
  data() {
    return {
      percent: 10,
      isDeducted: false,
    };
  },
  created() {},
  computed: {
    deductDf() {
      return this.calculateDeduct(this.total.paidDf - this.total.labDf);
    },
    deductCf() {
      return this.calculateDeduct(this.total.paidCf - this.total.labCf);
    },
    deductTotal() {
      return this.calculateDeduct(this.total.paid - this.total.labTotal);
    },
  },
  watch: {},
  methods: {
    calculateDeduct(val) {
      if (!this.isDeducted) return 0;
      return this.toFixed2IfNecessary((val * this.percent) / 100);
    },
    toFixed2IfNecessary(value) {
      return +parseFloat(value).toFixed(2);
    },
    handleDeductChange(e) {
      this.$emit("updateIsDeducted", this.isDeducted);
    },
  },

  async mounted() {
    this.isDeducted = Boolean(
      JSON.parse(localStorage.getItem("reportSetting") || "{}")?.isDeducted
    );
  },
  updated() {},
};
</script>

<style>
.sr-only {
  display: none;
}
</style>
